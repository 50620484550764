@import url("https://use.typekit.net/vgf7cgl.css");

$color: #00a2ff;


.App {
	background-color: #fbc638;
	text-align: center;
	height: 100vh;
}

.txtTitle {
	display: flex;
	justify-content: center;
	padding: 2% 2%;
}

h1 {
	font-family: ltc-broadway,sans-serif;
	font-weight: 400;
	font-style: normal;
	font-size: 5vh;
}

// .paw{
// 	height: 3.5%;
// 	position: absolute;
// 	transform: rotate(40deg);
// 	top: 2%;
// 	right: ;
// }

.mainContainer {
	// margin-top: -10%;
	// background-color: #00a2ff;
	display: inline-flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	height: 61%;
	width: 60%;
}

.animation {
	margin-left: -1rem;
}

.button {
	display: inline-block;
	padding: 1rem 2rem;
	border-radius: 10rem;
	color: #fff;
	font-size: 2.5vh;
	transition: all .3s;
	position: relative;
	z-index: 1;
	border: transparent;
	&:after {
		content: '';
		position: absolute;
		bottom: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background-color: $color;
		border-radius: 10rem;
		z-index: -2;
	}
	&:before {
		content: '';
		position: absolute;
		bottom: 0;
		left: 0;
		width: 0%;
		height: 100%;
		background-color: darken($color, 15%);
		transition: all .3s;
		border-radius: 10rem;
		z-index: -1;
	}
	&:hover {
		color: #fff;
		&:before {
			width: 100%;
		}
	}
}